* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {

  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#505050;
  border-radius: 10px;
}