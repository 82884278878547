.container {
  display: grid;
  grid-template-columns: 15rem 1fr;
  grid-template-rows: 4rem 1fr;
  min-height: 100vh;
}

.container > nav {
  grid-column: 1 / 3;
  grid-row: 1;
}

.container > main {
  grid-column: 2;
  grid-row: 2;
  overflow-x: hidden;
}

.container > aside {
  grid-column: 1;
  grid-row: 2;
}


@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1fr;
  }

  .container > nav {
    grid-column: 1;
    grid-row: 1;
  }

  .container > main {
    grid-column: 1;
    grid-row: 2;
  }

  .container > aside {
    display: none;
  }

  
}